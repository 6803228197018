import React from "react"
import RacepageFS from "../components/racepageFS"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Volta Ciclista a Catalunya",
      edition: 2021,
      what: "Startlist",
      updated: "2021-03-22 17:52:11",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21798: {
        teamId: 21798,
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00ebn Team",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21799: {
        teamId: 21799,
        teamUciCode: "AST",
        teamName: "Astana-Premier Tech",
        teamNationCode: "KAZ",
        teamNationName: "Kazakhstan",
      },
      21800: {
        teamId: 21800,
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
        teamNationName: "Bahrain",
      },
      21805: {
        teamId: 21805,
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21807: {
        teamId: 21807,
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21808: {
        teamId: 21808,
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21811: {
        teamId: 21811,
        teamUciCode: "EFN",
        teamName: "EF Education-NIPPO",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21813: {
        teamId: 21813,
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21817: {
        teamId: 21817,
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
        teamNationName: "Israel",
      },
      21819: {
        teamId: 21819,
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21821: {
        teamId: 21821,
        teamUciCode: "BEX",
        teamName: "Team BikeExchange",
        teamNationCode: "AUS",
        teamNationName: "Australia",
      },
      21822: {
        teamId: 21822,
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
        teamNationName: "Spain",
      },
      21824: {
        teamId: 21824,
        teamUciCode: "TQA",
        teamName: "Team Qhubeka ASSOS",
        teamNationCode: "RSA",
        teamNationName: "South Africa",
      },
      21826: {
        teamId: 21826,
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
        teamNationName: "Great Britain",
      },
      21829: {
        teamId: 21829,
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
        teamNationName: "Netherlands",
      },
      21832: {
        teamId: 21832,
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21836: {
        teamId: 21836,
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21838: {
        teamId: 21838,
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
        teamNationName: "United Arab Emirates",
      },
      21855: {
        teamId: 21855,
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21857: {
        teamId: 21857,
        teamUciCode: "EUS",
        teamName: "Euskaltel-Euskadi",
        teamNationCode: "ESP",
        teamNationName: "Spain",
      },
      21858: {
        teamId: 21858,
        teamUciCode: "GAZ",
        teamName: "Gazprom-RusVelo",
        teamNationCode: "RUS",
        teamNationName: "Russia",
      },
      21861: {
        teamId: 21861,
        teamUciCode: "RLY",
        teamName: "Rally Cycling",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21864: {
        teamId: 21864,
        teamUciCode: "ARK",
        teamName: "Team Ark\u00e9a-Samsic",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      22924: {
        teamId: 22924,
        teamUciCode: "EKP",
        teamName: "Equipo Kern Pharma",
        teamNationCode: "ESP",
        teamNationName: "Spain",
      },
    },
    riders: {
      1: {
        id: 38,
        startno: 1,
        firstName: "Alejandro",
        lastName: "Valverde",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1980-04-25",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      2: {
        id: 1527,
        startno: 2,
        firstName: "Enric",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-01-07",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      3: {
        id: 532,
        startno: 3,
        firstName: "Dario",
        lastName: "Cataldo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1985-03-17",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      4: {
        id: 7781,
        startno: 4,
        firstName: "Carlos",
        lastName: "Verona",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-11-04",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      5: {
        id: 46626,
        startno: 5,
        firstName: "Sergio",
        lastName: "Samitier",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-08-31",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      6: {
        id: 10996,
        startno: 6,
        firstName: "Antonio",
        lastName: "Pedrero",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-10-23",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      7: {
        id: 25726,
        startno: 7,
        firstName: "Marc",
        lastName: "Soler",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-11-22",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      11: {
        id: 3352,
        startno: 11,
        firstName: "George",
        lastName: "Bennett",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-04-07",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      12: {
        id: 48008,
        startno: 12,
        firstName: "Sepp",
        lastName: "Kuss",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-09-13",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      13: {
        id: 730,
        startno: 13,
        firstName: "Steven",
        lastName: "Kruijswijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-06-07",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      14: {
        id: 41,
        startno: 14,
        firstName: "Robert",
        lastName: "Gesink",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-05-31",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      15: {
        id: 18564,
        startno: 15,
        firstName: "Koen",
        lastName: "Bouwman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-02",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      16: {
        id: 22143,
        startno: 16,
        firstName: "Antwan",
        lastName: "Tolhoek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-04-29",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      17: {
        id: 39009,
        startno: 17,
        firstName: "Chris",
        lastName: "Harper",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-11-23",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      21: {
        id: 50303,
        startno: 21,
        firstName: "Joao",
        lastName: "Almeida",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1998-08-05",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      22: {
        id: 14791,
        startno: 22,
        firstName: "Fausto",
        lastName: "Masnada",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-11-06",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      23: {
        id: 10103,
        startno: 23,
        firstName: "Josef",
        lastName: "Cern\u00fd",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1993-05-11",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      24: {
        id: 27295,
        startno: 24,
        firstName: "James",
        lastName: "Knox",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-11-04",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      25: {
        id: 752,
        startno: 25,
        firstName: "Dries",
        lastName: "Devenyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1983-07-22",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      26: {
        id: 3165,
        startno: 26,
        firstName: "Pieter",
        lastName: "Serry",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      27: {
        id: 27111,
        startno: 27,
        firstName: "Remi",
        lastName: "Cavagna",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-08-10",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      31: {
        id: 114,
        startno: 31,
        firstName: "Rui",
        lastName: "Costa",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1986-10-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      32: {
        id: 48539,
        startno: 32,
        firstName: "Brandon",
        lastName: "McNulty",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1998-04-02",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      33: {
        id: 46163,
        startno: 33,
        firstName: "Marc",
        lastName: "Hirschi",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-08-24",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      34: {
        id: 34624,
        startno: 34,
        firstName: "Sebastian",
        lastName: "Molano",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-04-11",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      35: {
        id: 3356,
        startno: 35,
        firstName: "Joe",
        lastName: "Dombrowski",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1991-05-12",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      36: {
        id: 81824,
        startno: 36,
        firstName: "Andres",
        lastName: "Ardila",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1999-06-02",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      37: {
        id: 7590,
        startno: 37,
        firstName: "David",
        lastName: "De La Cruz",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-05-06",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      41: {
        id: 563,
        startno: 41,
        firstName: "Richie",
        lastName: "Porte",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1985-01-30",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      42: {
        id: 23097,
        startno: 42,
        firstName: "Richard",
        lastName: "Carapaz",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-05-29",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      43: {
        id: 20147,
        startno: 43,
        firstName: "Adam",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      44: {
        id: 716,
        startno: 44,
        firstName: "Geraint",
        lastName: "Thomas",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1986-05-25",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      45: {
        id: 2322,
        startno: 45,
        firstName: "Rohan",
        lastName: "Dennis",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-05-28",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      46: {
        id: 871,
        startno: 46,
        firstName: "Jonathan",
        lastName: "Castroviejo",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-04-27",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      47: {
        id: 2313,
        startno: 47,
        firstName: "Luke",
        lastName: "Rowe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-03-10",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      51: {
        id: 37446,
        startno: 51,
        firstName: "Jai",
        lastName: "Hindley",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-05-05",
        teamId: 21832,
        teamName: "Team DSM",
      },
      52: {
        id: 37447,
        startno: 52,
        firstName: "Michael",
        lastName: "Storer",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1997-02-28",
        teamId: 21832,
        teamName: "Team DSM",
      },
      53: {
        id: 9322,
        startno: 53,
        firstName: "Chad",
        lastName: "Haga",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1988-08-26",
        teamId: 21832,
        teamName: "Team DSM",
      },
      54: {
        id: 42185,
        startno: 54,
        firstName: "Chris",
        lastName: "Hamilton",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-05-18",
        teamId: 21832,
        teamName: "Team DSM",
      },
      55: {
        id: 37756,
        startno: 55,
        firstName: "Max",
        lastName: "Kanter",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-22",
        teamId: 21832,
        teamName: "Team DSM",
      },
      56: {
        id: 189,
        startno: 56,
        firstName: "Nicolas",
        lastName: "Roche",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1984-07-03",
        teamId: 21832,
        teamName: "Team DSM",
      },
      57: {
        id: 78129,
        startno: 57,
        firstName: "Thymen",
        lastName: "Arensman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-12-04",
        teamId: 21832,
        teamName: "Team DSM",
      },
      61: {
        id: 784,
        startno: 61,
        firstName: "Peter",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1990-01-26",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      62: {
        id: 2165,
        startno: 62,
        firstName: "Wilco",
        lastName: "Kelderman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1991-03-25",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      63: {
        id: 27289,
        startno: 63,
        firstName: "Lennard",
        lastName: "K\u00e4mna",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1996-09-09",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      64: {
        id: 45364,
        startno: 64,
        firstName: "Jordi",
        lastName: "Meeus",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-01",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      65: {
        id: 45602,
        startno: 65,
        firstName: "Ide",
        lastName: "Schelling",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-06",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      66: {
        id: 47841,
        startno: 66,
        firstName: "Ben",
        lastName: "Zwiehoff",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-22",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      67: {
        id: 82254,
        startno: 67,
        firstName: "Frederik",
        lastName: "Wandahl",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2001-05-09",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      71: {
        id: 46625,
        startno: 71,
        firstName: "\u00d3scar",
        lastName: "Rodr\u00edguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-05-06",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      72: {
        id: 26,
        startno: 72,
        firstName: "Luis Le\u00f3n",
        lastName: "S\u00e1nchez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-12-24",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      73: {
        id: 45445,
        startno: 73,
        firstName: "Vadim",
        lastName: "Pronskiy",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1998-06-04",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      74: {
        id: 27350,
        startno: 74,
        firstName: "Jonas",
        lastName: "Gregaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-07-30",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      75: {
        id: 64106,
        startno: 75,
        firstName: "Harold",
        lastName: "Tejada",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-04-27",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      76: {
        id: 44341,
        startno: 76,
        firstName: "Stefan",
        lastName: "de Bod",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1996-11-17",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      77: {
        id: 18117,
        startno: 77,
        firstName: "Merhawi",
        lastName: "Kudus",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1994-01-23",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      81: {
        id: 2741,
        startno: 81,
        firstName: "Gianluca",
        lastName: "Brambilla",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-22",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      82: {
        id: 4004,
        startno: 82,
        firstName: "Kenny",
        lastName: "Elissonde",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-07-22",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      83: {
        id: 54660,
        startno: 83,
        firstName: "Juan Pedro",
        lastName: "Lopez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-07-31",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      84: {
        id: 20384,
        startno: 84,
        firstName: "Giulio",
        lastName: "Ciccone",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-12-20",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      85: {
        id: 46182,
        startno: 85,
        firstName: "Michel",
        lastName: "Ries",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1998-03-11",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      86: {
        id: 7572,
        startno: 86,
        firstName: "Alexander",
        lastName: "Kamp",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1993-12-14",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      87: {
        id: 77103,
        startno: 87,
        firstName: "Mattias",
        lastName: "Skjelmose Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2000-09-26",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      91: {
        id: 69890,
        startno: 91,
        firstName: "Attila",
        lastName: "Valter",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-06-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      92: {
        id: 26520,
        startno: 92,
        firstName: "Matteo",
        lastName: "Badilatti",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1992-07-30",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      93: {
        id: 6184,
        startno: 93,
        firstName: "Sebastien",
        lastName: "Reichenbach",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1989-05-28",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      94: {
        id: 6260,
        startno: 94,
        firstName: "Antoine",
        lastName: "Duchesne",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1991-09-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      95: {
        id: 352,
        startno: 95,
        firstName: "Matthieu",
        lastName: "Ladagnous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1984-12-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      96: {
        id: 38923,
        startno: 96,
        firstName: "Simon",
        lastName: "Guglielmi",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-07-01",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      97: {
        id: 164,
        startno: 97,
        firstName: "William",
        lastName: "Bonnet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1982-06-25",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      101: {
        id: 16992,
        startno: 101,
        firstName: "Ruben",
        lastName: "Guerreiro",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1994-07-06",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      102: {
        id: 117,
        startno: 102,
        firstName: "Rigoberto",
        lastName: "Uran",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1987-01-26",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      103: {
        id: 18059,
        startno: 103,
        firstName: "Hugh",
        lastName: "Carthy",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1994-07-09",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      104: {
        id: 33263,
        startno: 104,
        firstName: "Jonathan",
        lastName: "Caicedo",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-03-28",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      105: {
        id: 78384,
        startno: 105,
        firstName: "Diego",
        lastName: "Camargo",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1998-05-03",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      106: {
        id: 799,
        startno: 106,
        firstName: "Tejay",
        lastName: "Van Garderen",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1988-08-12",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      107: {
        id: 6309,
        startno: 107,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      111: {
        id: 6242,
        startno: 111,
        firstName: "Simon",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      112: {
        id: 3213,
        startno: 112,
        firstName: "Esteban",
        lastName: "Chaves",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1990-01-17",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      113: {
        id: 1018,
        startno: 113,
        firstName: "Tanel",
        lastName: "Kangert",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1987-03-11",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      114: {
        id: 37445,
        startno: 114,
        firstName: "Lucas",
        lastName: "Hamilton",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-02-12",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      115: {
        id: 10975,
        startno: 115,
        firstName: "Dion",
        lastName: "Smith",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1993-03-03",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      116: {
        id: 1303,
        startno: 116,
        firstName: "Brent",
        lastName: "Bookwalter",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1984-02-16",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      117: {
        id: 42175,
        startno: 117,
        firstName: "Callum",
        lastName: "Scotson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-08-10",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      121: {
        id: 929,
        startno: 121,
        firstName: "Wout",
        lastName: "Poels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-10-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      122: {
        id: 55807,
        startno: 122,
        firstName: "Hermann",
        lastName: "Pernsteiner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1990-08-07",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      123: {
        id: 81835,
        startno: 123,
        firstName: "Santiago",
        lastName: "Buitrago",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1999-09-26",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      124: {
        id: 16687,
        startno: 124,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      125: {
        id: 37867,
        startno: 125,
        firstName: "Kevin",
        lastName: "Inkelaar",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-07-08",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      126: {
        id: 27210,
        startno: 126,
        firstName: "Domen",
        lastName: "Novak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1995-07-24",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      127: {
        id: 37376,
        startno: 127,
        firstName: "Stephen",
        lastName: "Williams",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1996-06-09",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      131: {
        id: 21921,
        startno: 131,
        firstName: "Cl\u00e9ment",
        lastName: "Venturini",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-10-16",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      132: {
        id: 51244,
        startno: 132,
        firstName: "Cl\u00e9ment",
        lastName: "Champoussin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-05-29",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      133: {
        id: 6413,
        startno: 133,
        firstName: "Bob",
        lastName: "Jungels",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-09-22",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      134: {
        id: 37761,
        startno: 134,
        firstName: "Jaakko",
        lastName: "H\u00e4nninen",
        nationCode: "FIN",
        nationName: "Finland",
        birthDate: "1997-04-16",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      135: {
        id: 852,
        startno: 135,
        firstName: "Ben",
        lastName: "Gastauer",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1987-11-14",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      136: {
        id: 918,
        startno: 136,
        firstName: "Tony",
        lastName: "Gallopin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-05-24",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      137: {
        id: 22267,
        startno: 137,
        firstName: "Fran\u00e7ois",
        lastName: "Bidard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-03-19",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      141: {
        id: 53723,
        startno: 141,
        firstName: "Andreas",
        lastName: "Kron",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-06-01",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      142: {
        id: 1783,
        startno: 142,
        firstName: "Thomas",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-11-06",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      143: {
        id: 39387,
        startno: 143,
        firstName: "Harm",
        lastName: "Vanhoucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-06-17",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      144: {
        id: 70377,
        startno: 144,
        firstName: "Maxim",
        lastName: "Van Gils",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-11-25",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      145: {
        id: 27332,
        startno: 145,
        firstName: "Steff",
        lastName: "Cras",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-02-13",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      146: {
        id: 51348,
        startno: 146,
        firstName: "Sylvain",
        lastName: "Moniquet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-14",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      147: {
        id: 2281,
        startno: 147,
        firstName: "Tomasz",
        lastName: "Marczynski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1984-03-06",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      151: {
        id: 10807,
        startno: 151,
        firstName: "Ruben",
        lastName: "Fernandez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-03-01",
        teamId: 21807,
        teamName: "Cofidis",
      },
      152: {
        id: 3044,
        startno: 152,
        firstName: "Nicolas",
        lastName: "Edet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-12-03",
        teamId: 21807,
        teamName: "Cofidis",
      },
      153: {
        id: 27303,
        startno: 153,
        firstName: "R\u00e9my",
        lastName: "Rochas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-05-18",
        teamId: 21807,
        teamName: "Cofidis",
      },
      154: {
        id: 1323,
        startno: 154,
        firstName: "Jose",
        lastName: "Herrada",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1985-10-01",
        teamId: 21807,
        teamName: "Cofidis",
      },
      155: {
        id: 71208,
        startno: 155,
        firstName: "Thomas",
        lastName: "Champion",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-09-08",
        teamId: 21807,
        teamName: "Cofidis",
      },
      156: {
        id: 27308,
        startno: 156,
        firstName: "Fernando",
        lastName: "Barcel\u00f3",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-01-06",
        teamId: 21807,
        teamName: "Cofidis",
      },
      157: {
        id: 6257,
        startno: 157,
        firstName: "Natnael",
        lastName: "Berhane",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1991-01-05",
        teamId: 21807,
        teamName: "Cofidis",
      },
      161: {
        id: 6415,
        startno: 161,
        firstName: "Louis",
        lastName: "Meintjes",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1992-02-21",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      162: {
        id: 602,
        startno: 162,
        firstName: "Jan",
        lastName: "Bakelants",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-02-14",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      163: {
        id: 6269,
        startno: 163,
        firstName: "Jan",
        lastName: "Hirt",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1991-01-22",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      164: {
        id: 346,
        startno: 164,
        firstName: "Rein",
        lastName: "Taaram\u00e4e",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1987-04-24",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      165: {
        id: 20378,
        startno: 165,
        firstName: "Simone",
        lastName: "Petilli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      166: {
        id: 71887,
        startno: 166,
        firstName: "Alexander",
        lastName: "Evans",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1997-01-28",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      167: {
        id: 51243,
        startno: 167,
        firstName: "J\u00e9r\u00e9my",
        lastName: "Bellicaud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-06-08",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      171: {
        id: 27139,
        startno: 171,
        firstName: "Robert",
        lastName: "Power",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-05-11",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      172: {
        id: 16777,
        startno: 172,
        firstName: "Kilian",
        lastName: "Frankiny",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1994-01-26",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      173: {
        id: 2503,
        startno: 173,
        firstName: "Sander",
        lastName: "Armee",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-12-10",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      174: {
        id: 6559,
        startno: 174,
        firstName: "Reinardt",
        lastName: "Janse Van Rensburg",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1989-02-03",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      175: {
        id: 61463,
        startno: 175,
        firstName: "Connor",
        lastName: "Brown",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-08-06",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      176: {
        id: 14658,
        startno: 176,
        firstName: "Karel",
        lastName: "Vacek",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "2000-09-09",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      177: {
        id: 52660,
        startno: 177,
        firstName: "Sean",
        lastName: "Bennett",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-03-31",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      181: {
        id: 713,
        startno: 181,
        firstName: "Chris",
        lastName: "Froome",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1985-05-20",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      182: {
        id: 51,
        startno: 182,
        firstName: "Daniel",
        lastName: "Martin",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1986-08-20",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      183: {
        id: 16569,
        startno: 183,
        firstName: "Michael",
        lastName: "Woods",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1986-10-12",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      184: {
        id: 706,
        startno: 184,
        firstName: "Daryl",
        lastName: "Impey",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1984-12-06",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      185: {
        id: 18490,
        startno: 185,
        firstName: "Alexander",
        lastName: "Cataford",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1993-09-01",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      186: {
        id: 27101,
        startno: 186,
        firstName: "Omer",
        lastName: "Goldstein",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1996-08-13",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      187: {
        id: 2294,
        startno: 187,
        firstName: "Reto",
        lastName: "Hollenstein",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1985-08-22",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      201: {
        id: 2276,
        startno: 201,
        firstName: "Nairo",
        lastName: "Quintana",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1990-02-04",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      202: {
        id: 965,
        startno: 202,
        firstName: "Anthony",
        lastName: "Delaplace",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-11",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      203: {
        id: 7771,
        startno: 203,
        firstName: "Lukasz",
        lastName: "Owsian",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-02-24",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      204: {
        id: 63050,
        startno: 204,
        firstName: "Matis",
        lastName: "Louvel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-07-19",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      205: {
        id: 3133,
        startno: 205,
        firstName: "Laurent",
        lastName: "Pichon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-07-19",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      206: {
        id: 16635,
        startno: 206,
        firstName: "Elie",
        lastName: "Gesbert",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-07-01",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      207: {
        id: 39175,
        startno: 207,
        firstName: "Alan",
        lastName: "Riou",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-04-02",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      211: {
        id: 9128,
        startno: 211,
        firstName: "Ilnur",
        lastName: "Zakarin",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1989-09-15",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
      },
      212: {
        id: 6526,
        startno: 212,
        firstName: "Sergey",
        lastName: "Chernetskiy",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1990-04-09",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
      },
      213: {
        id: 2320,
        startno: 213,
        firstName: "Pavel",
        lastName: "Kochetkov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1986-03-07",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
      },
      214: {
        id: 36,
        startno: 214,
        firstName: "Roman",
        lastName: "Kreuziger",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1986-05-06",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
      },
      215: {
        id: 16683,
        startno: 215,
        firstName: "Artem",
        lastName: "Nych",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1995-03-21",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
      },
      216: {
        id: 16685,
        startno: 216,
        firstName: "Dmitriy",
        lastName: "Strakhov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1995-05-17",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
      },
      217: {
        id: 39088,
        startno: 217,
        firstName: "Denis",
        lastName: "Nekrasov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1997-02-19",
        teamId: 21858,
        teamName: "Gazprom-RusVelo",
      },
      221: {
        id: 7663,
        startno: 221,
        firstName: "Joey",
        lastName: "Rosskopf",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-09-04",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      222: {
        id: 3360,
        startno: 222,
        firstName: "Gavin",
        lastName: "Mannion",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1991-08-24",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      223: {
        id: 32679,
        startno: 223,
        firstName: "Colin",
        lastName: "Joyce",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-08-06",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      224: {
        id: 2336,
        startno: 224,
        firstName: "Benjamin",
        lastName: "King",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-03-22",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      225: {
        id: 68291,
        startno: 225,
        firstName: "Nickolas",
        lastName: "Zukowsky",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1998-06-03",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      226: {
        id: 3354,
        startno: 226,
        firstName: "Nathan",
        lastName: "Brown",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1991-07-07",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      227: {
        id: 1905,
        startno: 227,
        firstName: "Rob",
        lastName: "Britton",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1984-09-22",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      231: {
        id: 46622,
        startno: 231,
        firstName: "Mart\u00ed ",
        lastName: "M\u00e1rquez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-02-09",
        teamId: 22924,
        teamName: "Equipo Kern Pharma",
      },
      232: {
        id: 46617,
        startno: 232,
        firstName: "Jaime",
        lastName: "Castrillo",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-03-13",
        teamId: 22924,
        teamName: "Equipo Kern Pharma",
      },
      233: {
        id: 46181,
        startno: 233,
        firstName: "Francisco",
        lastName: "Galv\u00e1n",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-12-01",
        teamId: 22924,
        teamName: "Equipo Kern Pharma",
      },
      234: {
        id: 57355,
        startno: 234,
        firstName: "Urko",
        lastName: "Berrade",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-11-28",
        teamId: 22924,
        teamName: "Equipo Kern Pharma",
      },
      235: {
        id: 61838,
        startno: 235,
        firstName: "Savva",
        lastName: "Novikov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1999-07-27",
        teamId: 22924,
        teamName: "Equipo Kern Pharma",
      },
      236: {
        id: 95531,
        startno: 236,
        firstName: "Daniel Alejandro",
        lastName: "Mendez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "2000-06-02",
        teamId: 22924,
        teamName: "Equipo Kern Pharma",
      },
      237: {
        id: 73811,
        startno: 237,
        firstName: "Roger",
        lastName: "Adri\u00e1",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-04-18",
        teamId: 22924,
        teamName: "Equipo Kern Pharma",
      },
      241: {
        id: 2297,
        startno: 241,
        firstName: "Luis Angel",
        lastName: "Mate",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-03-23",
        teamId: 21857,
        teamName: "Euskaltel-Euskadi",
      },
      242: {
        id: 32201,
        startno: 242,
        firstName: "Antonio",
        lastName: "Soto",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-09-22",
        teamId: 21857,
        teamName: "Euskaltel-Euskadi",
      },
      243: {
        id: 7635,
        startno: 243,
        firstName: "Mikel",
        lastName: "Bizkarra",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-08-21",
        teamId: 21857,
        teamName: "Euskaltel-Euskadi",
      },
      244: {
        id: 2340,
        startno: 244,
        firstName: "Juan Jose",
        lastName: "Lobato",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1988-12-29",
        teamId: 21857,
        teamName: "Euskaltel-Euskadi",
      },
      245: {
        id: 37388,
        startno: 245,
        firstName: "Joan",
        lastName: "Bou",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-01-16",
        teamId: 21857,
        teamName: "Euskaltel-Euskadi",
      },
      246: {
        id: 18241,
        startno: 246,
        firstName: "Mikel",
        lastName: "Iturria",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-03-16",
        teamId: 21857,
        teamName: "Euskaltel-Euskadi",
      },
      247: {
        id: 37392,
        startno: 247,
        firstName: "Gotzon",
        lastName: "Martin",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-02-15",
        teamId: 21857,
        teamName: "Euskaltel-Euskadi",
      },
    },
  }
  const race = "Volta Catalunya"
  const raceID = 14

  return (
    <RacepageFS
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
